import React from 'react';

import { Form } from 'react-final-form';

import { Button } from '@travauxlib/shared/src/components/DesignSystem/components/Buttons/Button';
import { DropdownField } from '@travauxlib/shared/src/components/DesignSystem/components/Dropdown/Field';
import { InputField } from '@travauxlib/shared/src/components/DesignSystem/components/Input/Field';
import { FormLabel } from '@travauxlib/shared/src/components/FormLabel';
import {
  required,
  requiredValidEmail,
  requiredValidPhoneNumber,
} from '@travauxlib/shared/src/utils/form';

import { EstimateurFormValues, Investissement, Reseau } from '../types';
import { reseauLabel, tempsInvestiLabels } from '../utils/constants';

type Props = {
  onSubmit: (values: EstimateurFormValues) => void;
  initialValues?: EstimateurFormValues;
};

export const EstimateurForm: React.FC<Props> = ({ onSubmit, initialValues }) => (
  <Form<EstimateurFormValues> initialValues={initialValues} onSubmit={values => onSubmit(values)}>
    {({ handleSubmit, submitting }) => (
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <div className="flex flex-col gap-sm">
          <div>
            <div className="flex gap-md mb-sm">
              <InputField
                className="flex-grow"
                label="Prénom"
                id="firstname"
                name="firstname"
                validate={required}
              />
              <InputField
                validate={required}
                className="flex-grow"
                label="Nom"
                id="lastname"
                name="lastname"
              />
            </div>
            <InputField
              className="flex-grow mb-sm"
              id="phoneNumber"
              label="Téléphone"
              name="phoneNumber"
              validate={requiredValidPhoneNumber}
            />
            <InputField
              className="flex-grow mb-lg"
              validate={requiredValidEmail}
              label="Email"
              id="email"
              name="email"
            />
            <FormLabel
              id="tempsInvesti"
              className="text-b2"
              label="A quel point êtes vous pret à vous investir avec hemea ?"
            />
            <DropdownField
              validate={required}
              id="tempsInvesti"
              name="tempsInvesti"
              placeholder="A quel point êtes vous pret à vous investir avec hemea ?"
              className="mb-sm"
              options={[
                {
                  label: tempsInvestiLabels[Investissement.DOUBLE_ACTIVITE],
                  value: Investissement.DOUBLE_ACTIVITE,
                },
                {
                  label: tempsInvestiLabels[Investissement.EQUILIBRE],
                  value: Investissement.EQUILIBRE,
                },
                {
                  label: tempsInvestiLabels[Investissement.COMPLET],
                  value: Investissement.COMPLET,
                },
              ]}
            />
          </div>
          <div>
            <FormLabel
              id="reseauApporteur"
              className="text-b2"
              label="Avez vous un réseau de prescripteurs ?"
            />
            <DropdownField
              id="reseauApporteur"
              validate={required}
              name="reseauApporteur"
              placeholder="Avez vous un réseau de prescripteurs ?"
              className="mb-lg"
              options={[
                {
                  label: reseauLabel[Reseau.AUCUN],
                  value: Reseau.AUCUN,
                },
                {
                  label: reseauLabel[Reseau.MOYEN],
                  value: Reseau.MOYEN,
                },
                {
                  label: reseauLabel[Reseau.GRAND],
                  value: Reseau.GRAND,
                },
              ]}
            />
          </div>
        </div>
        <Button loading={submitting} type="submit">
          Calculer mes revenus
        </Button>
      </form>
    )}
  </Form>
);
